.page-help{
    background: #fff;
    @media (max-width:$tablet){
        padding-top: 0px !important;
        .banner-page{
            .text-encabezado{
                transform: translateY(0%);
            }
        }
    }
    .card{
        box-shadow: unset;
        border-radius: 0px;
        border: 0px;
    }
    .list-group-item{
        &:first-child{
            border-top-left-radius: 0rem !important;
            border-top-right-radius: 0rem !important;
        }
        &:last-child{
            border-bottom-left-radius: 0rem !important;
            border-bottom-right-radius: 0rem !important;
        }
        &.active{
            background:transparent;
            border-right: 3px solid var(--orange);
        }
    }
    .text-submenu{
        font-size: 12px;
    }
    .content-submenu-3{
        width: 33%;
        @media (max-width:$tablet){
            width: 50%;
        }
    }
    .list-group-item.active {
        font-family: "Montserrat-ExtraBold";
    }
}