.fp-slidesContainer{
    transform: translate3d(0px ,0px ,0px) !important;
    .fp-slide {
        visibility: visible;
        opacity: 0;
        transition: 500ms ease all;
    }
    .fp-slide.active {
        visibility: visible;
        opacity: 1;
        z-index: 1;
    }
}
#fp-nav ul li .fp-tooltip.fp-right{
    transition: 300ms ease all;
}
#fp-nav ul li{
    transition: 500ms ease all;
    .fp-tooltip.fp-right{
        width: auto;
    }
    &.active{
        .fp-tooltip.fp-right{
            opacity: 1;
        }
    }
}
.fp-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    &::before{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        background: var(--black);
        opacity: .9;
        width: 100%;
        height: 100%;
    }
}
.fp-slidesNav{
    display: none;
    padding-left: 5%;
    ul li a {
        span{ 
            background:#fff;
            height: 12px !important;
            width: 12px !important;
            margin: -6px 0 0 -6px !important;
            transform: scale(0.8);
            transition: 300ms ease transform;
            &::before{
                content:'';
                position: absolute;
                top: -3px;
                left: -3px;
                border-radius: 50%;
                transform: scale(0);
                width: 18px;
                height: 18px;
                border:1px solid #fff;
                transition: 300ms ease transform;
            }
        }
        &.active{
            span{
                transform: scale(1);
                &::before{
                    transform: scale(1.1);
                }
            }
        }
    }
}

@media (max-width:$tablet) {
    .section {
        position: relative;
        padding-top: 0vw;
        padding-bottom: 0px;
    }
}

.page-home{
    .section-footer{
        @media (min-width:$tablet){
            .contenedor-footer{
                padding-bottom: 95px;
            }
        }
        @media (max-width:1109px){
            .contenedor-footer{
                padding-bottom: 1.5rem;
            }
        }
    }
    .section-banner{
        padding-top: 0%;
        padding-bottom: 1.5vw;
        @media (max-width:$tablet){
            padding-bottom: 0%;
        }
        h1,h4,p{
            text-align: left;
            @media (max-width:$tablet) {
                text-align: center;
            }
        }
        @media (max-width:$tablet){
            .content-slider{
                flex-direction: column;
            }
        }
        .content-box{
            .icon{
                width: 50px;
                height: 50px;
            }
            @media (max-width:$tablet){
                flex-direction: column;
                margin-top: 2rem !important;
                .icon{
                    width: 18vw;
                    height: 18vw;
                }
            }
            .box{
                margin: 1rem 1.5rem;
                @media (max-width:$tablet){
                    margin: 1rem 0rem;
                    align-items: center !important;
                    .text{
                        margin-left: 1rem !important;
                    }
                }
            }
        }
        .bottomPart{
            pointer-events: none;
            position: absolute;
            z-index: 2;
            padding-top: 8.5%;
            // @media (max-width:$tablet) {
            //     padding-top: 18.5%;
            // }
        }
        .fp-controlArrow{
            &.fp-next{
                border-width: 0px !important;
                border-color: transparent transparent transparent transparent !important;
                &::before{
                    left: -4rem;
                    transform: rotate(180deg);
                }
            }
            &.fp-prev{
                border-width: 0px !important;
                border-color: transparent transparent transparent transparent !important;
                opacity: .5;
                transition: 300ms ease opacity;
                &:hover{
                    opacity: 1;
                }
                &::before{
                    right: -4rem;
                }
            }
            &::before{
                content:'';
                background-image: url("./../img/arrowLeft.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                width: 2.8rem;
                height: 2.8rem;
                position: absolute;
                top: 0;
            }   
        }
        .slide{
            width: 100%;
            height: 100%;
            padding: 9vw 0vw 8vw;
            position: relative;
            .box-content{
                position: relative;
                width: 80%;
                margin: auto;
                margin-top: 2rem;
                @media (max-width:$tablet) {
                    width: 90%;
                    flex-direction: column;
                    margin-top: 0 !important;
                    padding-top: 20%;
                    padding-bottom: 15%;
                }
                .imgBtc{
                    width: 27vw;
                    height: 27vw;
                    @media (max-width:$tablet){
                        display: none;
                    }
                }
                .content-text{
                    width: 100%;
                }
                .content-buttons{
                    margin-top: 2.5vw;
                    @media (max-width:$tablet) {
                        // flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-top: 0vw;
                        .btn{
                            margin-top: 3vw;
                            width: fit-content;
                            // margin-left: unset !important;
                        }
                    }
                }
            }
        }
    }
    .section-ofrecemos{
        background: rgb(255,118,50);
        background: linear-gradient(90deg, rgba(255,118,50,1) 0%, rgba(255,226,69,1) 100%);
        @media (min-width:$tablet){
            .title{
                margin-bottom: 3rem;
            }
        }
        @media (max-width:$tablet){
            padding-top: 10%;
        }
        .bottomPart{
            margin-top: 4.5%;
            pointer-events: none;
            z-index: 2;
            padding-top: 8.5%;
            -webkit-mask-size: cover !important;
            @media (max-width:$tablet){
                margin-top: 7%;
            }
        }
        .topLeft{
            opacity: .1;
            width: 76%;
            height: 41vw;
            background-size: contain;
            transform: translateX(4%) translateY(-14%);
        }
        .bottomLeft1{
            width: 12vw;
            height: 17vw;
            transform: rotate(-10deg);
            left: 5%;
            bottom: 5%;
            opacity: .6;
            @media (max-width:$tablet){
                width: 8rem;
                height: 15rem;
                bottom: 38%;
            }
        }
        .bottomRight{
            width: 11vw;
            height: 19vw;
            bottom: -15%;
            z-index: 3;
            @media (max-width:$tablet){
                width: 11rem;
                height: 19rem;
                background:var(--gray) !important;
                z-index: 0;
            }
        }
        .icon{
            width: 100%;
            height: 8vw;
            @media (max-width:$tablet){
                width: 30vw;
                height: 30vw; 
            }
        }
        // .box{
        //     .w-80{
        //         @media (max-width:$tablet){
        //             width: 100%;
        //         }
        //     }
        // }
        .content-box-caracteristica{
            @media (max-width:$tablet){
                flex-direction: column;
                padding:0px 15px;
            }
            .box-caracteristica{
                margin: .5rem 1rem;
                background-color: #fff;
                height: 22vw;
                width: 27vw;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                border-radius: 1rem;
                padding:0 1.3rem;
                transform: scale(.9);
                &.shadow{
                    transform: scale(1.1);
                }
                @media (max-width:$tablet){
                    width: 100%;
                    height: auto;
                    transform: scale(1);
                    padding:1rem;
                    margin: 1rem 0rem;
                    &.shadow{
                        transform: scale(1);
                    }
                }
                .text-break{
                    font-size: .9rem;
                }
                .link-special{
                    margin-top: 1em;
                    a,a:link{
                        position: relative;
                        font-size: 14px;
                        &::before{
                            content:'';
                            width: 100%;
                            height: 2px;
                            background-color: var(--orange);
                            position: absolute;
                            bottom: -5px;
                            left: 0;
                        }
                    }
                }
            }
        }
        
    }
    .section-information{
        padding-top: 7%;
        padding-bottom: 10%;
        @media (max-width:$tablet){
            padding-top: 20%;
        }
        .bottomPart{
            margin-top: 9%;
            pointer-events: none;
            z-index: 2;
            padding-top: 8.5%;
            -webkit-mask-size: cover !important;
        }
        .tramado{
            width: 60%;
            height: 34.5vw;
            bottom: 10%;
            left: 10%;
            opacity: .08;
        }
        .topLeft{
            width: 18.95vw;
            height: 34.5vw;
            bottom: 22%;
            @media (max-width:$tablet) {
                width: 41.95vw;
                height: 77.5vw;
                bottom: 35%;
                z-index: -1;
            }
        }
        .w-60{
            @media (max-width:$tablet) {
                width: 90%;
                margin: auto;
                padding-left: 0 !important;
                text-align: center;
            }
        }
        .bottomLeft1{
            width: 10vw;
            height: 10vw;
            transform: rotate(-10deg);
            left: 18%;
            bottom: 5%;
            display: none;
        }
        .bottomRight1{
            width: 24vw;
            height: 12vw;
            right: 23%;
            @media (max-width:$tablet) {
                width: 14rem;
                height: 7rem;
                z-index: 0;
                right: 0;
                // right: -7rem;
            }
            // background: rgb(1,46,64);
            // background: linear-gradient(90deg, rgba(1,46,64,1) 0%, #141e30 100%);
        }
        .bottomRight2{
            width: 33vw;
            height: 35vw;
            background: linear-gradient(90deg, #ff7632 0%, #ffe245 100%);
            background-size: cover;
            @media (max-width:$tablet) {
                width: 83vw;
                height: 88vw;
                transform:translateY(10%);
            }
        }
        .bottomRight3{
            // width: 33vw;
            // height: 35vw;
            width: 44vw;
            height: 48vw;
            right: 4%;
            background-size: cover;
            @media (max-width:$tablet){
                width: 100vw;
                height: 105vw;
                right: unset;
                transform: translateX(-13vw) translateY(17vw);
            }
        }
        .box{
            position: relative;
            @media (max-width:$tablet){
                flex-direction: column;
            }
            .title{ margin-top: -2rem; }
            .content-img-bancos{
                width: 60%;
                @media (max-width:$tablet) {
                    width: 90%;
                    margin: auto;
                }
            }
        }
        .content-transaction-completed{
            padding-left: 7%;
            padding-right: 2%;
        }
    }
    .section-clientes{
        padding-top: 3%;
        @media (max-width:$tablet){
            padding-top: 10%;
        }
        .tramado{
            width: 60%;
            height: 34.5vw;
            bottom: 25%;
            left: 16%;
            opacity: .1;
        }
        .bgImg{ 
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            &::before{
                content:'';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--black);
                opacity: 1;
            }
        }
        .bottomPart{
            margin-top: 6%;
            pointer-events: none;
            z-index: 2;
            padding-top: 8.5%;
            -webkit-mask-size: cover !important;
            background: linear-gradient(90deg, #ff7632 0%, #ffe245 100%);
        }
        .topLeft{
            width: 12vw;
            height: 18.95vw;
            background: var(--gray);
            background-size: cover;
        }
        h1{
            @media (max-width:$tablet){
                margin-bottom: 1vw;
            }
        }
        .subtitle{
            font-size: 1.2rem;
            @media (max-width:$tablet){
                font-size: 5vw;
            }
        }
        .owl-dots{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
            .owl-dot{
                width: 1rem;
                height: 1rem;
                background-color: #fff;
                border-radius: 50%;
                transition: 300ms ease all;
                transform: scale(.7);
                margin: .2rem;
                @media (max-width: $tablet){
                    width: 4vw;
                    height: 4vw;
                }
                &.active{
                    transform: scale(1.1);
                    background-color: var(--orange);
                }
            }
        }
        .box{
            width: inherit;
            .item-client{
                margin-top: 1.6rem;
                .img-client{
                    margin: auto;
                    width: 6vw;
                    height: 6vw;
                    border-radius: 50%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    @media (max-width: $tablet){
                        width: 20vw;
                        height: 20vw;
                    }
                }
                .name-client{
                    margin-top: 1.1rem;
                    font-size: 1.3rem;
                    @media (max-width: $tablet){
                        font-size: 5vw;
                    }
                }
                .job-client{
                    font-size: .9rem;
                    @media (max-width: $tablet){
                        font-size: 4vw;
                    }
                }
                .comentario-client{
                    margin-top: 1.2rem !important;
                    margin:auto;
                    width: 60%;
                    @media (max-width: $tablet){
                        width: 90%;
                        font-size: 3vw;
                    }
                }
            }
        }
    }
    .section-ubicanos{
        @media (max-width:$tablet){
            height: 35rem;
            .content-buttons-maps{
                justify-content: flex-end !important;
                width: 97% !important;
                .btn-maps{
                    padding:unset !important;
                    width: 3.5rem;
                    height: 3.5rem;
                    background-color: var(--gray) !important;
                    color: var(--black) !important;
                    box-shadow: 0px 0px 12px rgba(1, 46, 64, 0.2);
                    i{
                        font-size: 2rem;
                    }
                }
            }
        }
        .topPart{
            height: 45vw;
            z-index: 2;
            -webkit-mask-size: cover;
            pointer-events: none;
            background: linear-gradient(90deg, #ff7632 0%, #ffe245 100%);
            @media (max-width:$tablet){
                height: 75vw;
            }
        }
        .btn-content{
            bottom: 3%;
            z-index: 3;
        }
        .bottomLeft{
            width: 10vw;
            height: 18vw;
            bottom: 35%;
            z-index: 2;
            background: var(--black);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: .8rem;
            i{ font-size: 2rem;margin-bottom: .5rem; }
            @media (max-width:$tablet){
                width: 8rem;
                height: 15rem;
                bottom: 16rem;
            }
            .box{
                transform: translateX(-.5rem);
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                a{
                    position: relative;
                    margin-bottom: .3rem;
                }
            }
        }
        .content-title{
            z-index: 2;
            // @media (max-width:$tablet){
            //     top: 0rem;
            // }
        }
    }
    .fixed-bottom-home{
        z-index: 3;
        box-shadow: 0px -31px 57px rgba(1, 6, 38, 0.2);
        border-top-left-radius: 80px;
        border-top-right-radius: 80px;
        @media (max-width:1109px){
            display: none;
        }
        .content-calculadora{
            display: flex;
            padding: 1rem 4rem;
            .content-cantidad{
                align-items: center;
                margin-right: 1rem;
                label{
                    margin-bottom: 0px;
                }
            }
        }
        .calculadora-resultado-visible{
            display: none;
        }
        .form-group{
            margin-bottom: 0rem;
            // .form-control{
            //     border-radius: 3rem;
            // }
            .btn{
                padding-top: 0px;
                padding-bottom: 0px;
                height: 100%;
                margin-left: 1rem;
            }
        }
    }
}