.section-footer{
    position: relative;
    // background: var(--black);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    @media (max-width:$tablet){
        margin-top: 5%;
    }
    .contenedor-footer{
        width: 1000px;
        margin: auto;
        padding-bottom: 1.5rem;
        @media (max-width:1050px){
            width: 90%;
            padding-bottom: 20vw;
        }
        .footer-logo{
            padding-top:4%;
            padding-bottom: 4%;
            @media (max-width:$tablet){
                padding-top:2%;
                padding-bottom: 7%;
            }
        }
        .footer-menu{
            padding-top: 5%;
            padding-bottom: 5%;
            @media (max-width:$tablet){
                padding-top: 7%;
                padding-bottom: 7%;
            }
        }
        .footer-copy{
            @media (max-width:$tablet){
                text-align: center;
                .width-sm-100{
                    width: 100%;
                }
            }
            .col-md-12{
                padding-top: 1.5rem;
            }
            color: rgba(255, 255, 255, 0.8);
            font-family: "Open Sans", sans-serif;
            // padding-bottom: 4%;
        }
    }
    // .footer-menu-links-page{
    //     @media (max-width:$tablet){
    //         display: none;
    //     }
    // }
    .topPart{
        display:none;
        pointer-events: none;
        z-index: 2;
        -webkit-mask-image: url("./../../img/bottomPart.svg");
        -webkit-mask-size: cover !important;
        background: var(--black);
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width:$tablet){
            display:none;
            // -webkit-mask-image: url("./../../img/TopPartFooter.svg");
            padding-top: 9%;
            position: absolute;
            top: -4.5%;
        }
    }
    ul{
        @media (max-width:$tablet){
            margin-top: 2rem;
        }
        li{
            line-height: 2.2em;
            color: #fff;
            // text-align:center;
            @media (max-width:$tablet){
                margin-left: 0.8rem;
            }
            a{ 
                color: rgba(255,255,255,.4); 
                font-family: "Open Sans", sans-serif;
                font-size: 14px;
                position: relative;
                transition: 500ms ease color;
                &.content-logo{ &::before{content: unset;} }
                &:hover{
                    color: rgba(255,255,255,1);
                }
            }
            .content-logo{
                @media (max-width:$tablet){
                    margin-bottom: 1rem;
                }
                .logo{
                    background: linear-gradient(30deg, #ffe245 0%, #ff7632 79%);
                    width: 4vw;
                    height: 4vw;
                    transition:500ms ease all;
                    @media (max-width:$tablet){
                        width: 15vw;
                        height: 15vw;
                    }
                }
                .logo-texto{
                    background-color: #fff;
                    width: 16vw;
                    height: 4.3vw;
                    transition: 500ms ease all;
                    @media (max-width:$tablet){
                        width: 50vw;
                        height: 15vw;
                    }
                }
            }
        }
    }
    .box-social{
        width: 30px;
        height: 30px;
        border:1px solid #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: .9rem;
        margin-left: .5rem;
        margin-right: .5rem;
        cursor: pointer;
        transition: 300ms ease all;
        @media (max-width:$tablet) {
            margin-top: 6vw;
            font-size: 5vw;
            width: 10vw;
            height: 10vw;
        }
        &:hover{
            background-color: #fff;
            color: var(--black);
        }
    }
    .copiryght{
        font-size: .8rem;
        @media (max-width:$tablet) {
            font-size: 3vw;
        }
        &::before{
            content:'';
            top: 0;
            left: 0;
            width: 100%;
            position: absolute;
            height: 2px;
            background: #fff;
            margin: auto;
            opacity: .1;
        }
    }
}