@font-face {
    font-family: "ArialRoundedMTStd-Bold";
    font-display: swap;
    src: url("../../fonts/ArialRoundedMTStd-Bold.woff2") format("woff2");
}
@font-face {
    font-family: "ArialRoundedMTStd-ExtraBold";
    font-display: swap;
    src: url("../../fonts/ArialRoundedMTStd-ExtraBold.woff2") format("woff2");
}
@font-face {
    font-family: "ArialRoundedMTStd-Light";
    font-display: swap;
    src: url("../../fonts/ArialRoundedMTStd-Light.woff2") format("woff2");
}
@font-face {
    font-family: "ArialRoundedMTStd";
    font-display: swap;
    src: url("../../fonts/ArialRoundedMTStd.woff2") format("woff2");
}
@font-face {
    font-family: "Comfortaa-Bold";
    font-display: swap;
    src: url("../../fonts/Comfortaa-Bold.woff2") format("woff2");
}
@font-face {
    font-family: "Comfortaa-Light";
    font-display: swap;
    src: url("../../fonts/Comfortaa-Light.woff2") format("woff2");
}
@font-face {
    font-family: "Roboto-Regular";
    font-display: swap;
    src: url("../../fonts/Roboto-Regular.woff2") format("woff2");
}
@font-face {
    font-family: "Montserrat-ExtraBold";
    font-display: swap;
    src: url("../../fonts/Montserrat-ExtraBold.woff2") format("woff2");
}
@font-face {
    font-family: "Montserrat-Bold";
    font-display: swap;
    src: url("../../fonts/Montserrat-Bold.woff2") format("woff2");
}
@font-face {
    font-family: "Montserrat-Regular";
    font-display: swap;
    src: url("../../fonts/Montserrat-Regular.woff2") format("woff2");
}
@font-face {
    font-family: "Montserrat-Light";
    font-display: swap;
    src: url("../../fonts/Montserrat-Light.woff2") format("woff2");
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-ExtraLightItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Work Sans';
    font-display: swap;
    src: url('../../fonts/WorkSans-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

.ArialRoundedMTStd-Bold{ font-family: "ArialRoundedMTStd-Bold"; }
.ArialRoundedMTStd-ExtraBold{ font-family: "ArialRoundedMTStd-ExtraBold"; }
.ArialRoundedMTStd-Light{ font-family: "ArialRoundedMTStd-Light"; }
.ArialRoundedMTStd{ font-family: "ArialRoundedMTStd"; }
.Comfortaa-Bold{ font-family: "Comfortaa-Bold"; }
.Comfortaa-Light{ font-family: "Comfortaa-Light"; }
.Roboto-Regular{ font-family: "Roboto-Regular"; }
.Montserrat-Regular{ font-family: "Montserrat-Regular"; }
.Montserrat-ExtraBold{ font-family: "Montserrat-ExtraBold"; }
.Montserrat-Bold{ font-family: "Montserrat-Bold"; }
.Montserrat-Light{ font-family: "Montserrat-Light"; }