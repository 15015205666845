.fixed-bottom{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    @media (max-width:$tablet){
        padding-top: 2vw;
        padding-bottom: 2vw;
        z-index: 3;
        // filter: drop-shadow(0px 10px #ced4da 20%);
        border-top: 1px solid #ced4da78;
        display: flex;
        justify-content: space-between;
        ul{
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            li{
                width: 100%;
                a{
                    color: var(--black);
                    // color:#6c757dcc;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    font-size: 3.5vw;
                    .icon{
                        width: 6.5vw;
                        height: 6.5vw;
                        background: var(--black);
                        // background:#6c757dcc;
                    }
                    &.active{
                        color: var(--orange);
                        .icon{
                            background: linear-gradient(90deg, rgba(255,226,69,1) 0%, rgba(255,118,50,1) 100%);
                        }
                    }
                }
                &.visible-menu{
                    &[data-visible=perfil]{
                        .content-fixed-bottom-login{
                            display: none;
                        }
                        .content-fixed-bottom-perfil{
                            display: flex;
                        }
                    }
                    &[data-visible=login]{
                        .content-fixed-bottom-login{
                            display: flex;
                        }
                        .content-fixed-bottom-perfil{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}