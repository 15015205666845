.page-profile{
    background: rgb(244, 245, 247);
    @media (max-width:$tablet){
        .section-footer{
            margin-top: 12%;
        }
    }
    .profile-component{
        .profile-img{
            cursor: pointer;
            .box-circle{
                position: absolute;
                bottom: -5%;
                right: 0;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .7rem;
            }
            img{
                width: 4.5vw;
                @media (max-width:$tablet){
                    width: 12vw;
                }
            }
        }
        .profile-inf{
            .profile-name{
                font-size: 1.3vw;
                @media (max-width:$tablet){
                    font-size: 4vw;
                }
            }
            .profile-email{
                font-size: .9vw;
                @media (max-width:$tablet){
                    font-size: 3.2vw;
                }
            }
        }
        .items-info{
            font-size: .8rem;
            .icon-circle{
                width:40px;
                height:40px;
                border:1px solid var(--blue);
                border-radius:50%;
                font-size: 1rem;
                color: var(--blue);
            }
        }
    }
    .content-dni-cara, .content-dni-sello, .content-dni-selfie{
        width:30%;
        @media (max-width:$tablet){
            width:100%
        }
    }
    .img-dni {
        background-color: var(--gray);
        border:4px solid var(--gray);
        border-radius: 10px;
        width: 100%;
        padding-top: 65%;
        &.is-invalid{
            background-color: #dc3545;
            border: 4px solid #dc3545;
        }
    }
    .input-group {
        .form-control{
            padding-left: 1.2rem !important;
        }
        .input-group-text{
            position: relative;
            z-index: 2;
            height: 100%;
            color: var(--black);
            background-color: var(--gray);
            border: 1px solid #ced4da;
            padding-left: .75rem;
            padding-right: .75rem;
            cursor: pointer;
            @media (max-width:$tablet){
                padding-left: .75rem;
                padding-right: .75rem;
            }
        }
    }
    .btn{
        &.btn-rounded-circle{
            padding: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            i{
                font-size: 14px !important;
            }
        }
    }
    .card-footer{
        position: relative;
        &.disabled{
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
            opacity: .6;
            &::before{
                z-index: 2;
                content:'Cargando...';
                width: 100%;
                position: absolute;
                left: 0;
                color: #fff;
            }
            &.bg-success{
                &::before{
                    background: var(--success);
                }
            }
            &.bg-orange{
                &::before{
                    background: var(--orange);
                }
            }
        }
    }
    .carousel-inner{
        border-top-right-radius: 1rem !important;
        border-top-left-radius: 1rem !important;
    }
    @media (max-width:$tablet){
        .user-profile{
            margin-top: 5vw;
            .title{
                font-size: 6vw;
            }
            small{
                font-size: 3vw;
                margin-top: -2vw;
            }
            img{
                width: 11vw;
            }
        }
        .cols-p-mobile-none {
            padding-right: 0px;
            padding-left: 0px;
            .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
        .btn{
            padding: 2vw 2.2rem;
        }
        .form-control{
            padding: 0vw 1.2rem;
            font-size: 3.5vw;
            height:3.125rem;
        }
        .card-header{
            font-size: 3.5vw;
            background: #fff;
        }
        .card-body{
            font-size: 3.5vw;
        }
        .card-footer{
            font-size: 3.5vw;
        }
    }
}