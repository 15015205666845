:root{
    --black:#010626;
    --blue:#012E40;
    // --gray:#eef3f0;
    --gray:#F2F2F2;
    --gray-2:#e9ecef;
    --gray-3:#f4f5f7;
    --yellow:#ffff1a;
    --orange:#FF8304;
    --orange2:#ff7632;
    --green:#012326;
    // --skyblue: #45dfe6;
    --skyblue:#05C4FF;
    --purple: #0D2269;
}

.c-black{ color:var(--black) }
.c-blue{ color:var(--blue) }
.c-gray{ color:var(--gray) }
.c-gray-2{ color:var(--gray-2) }
.c-gray-3{ color:var(--gray-3) }
.c-yellow{ color:var(--yellow) }
.c-orange{ color:var(--orange) }
.c-orange2{ color:var(--orange2) }
.c-green{ color:var(--green) }
.c-skyblue{ color:var(--skyblue) }
.c-purple{ color:var(--purple) }

.bg-black{ background-color:var(--black) !important; }
.bg-blue{ background-color:var(--blue) }
.bg-gray{ background-color:var(--gray) }
.bg-gray-2{ background-color:var(--gray-2) }
.bg-gray-3{ background-color:var(--gray-3) }
.bg-yellow{ background-color:var(--yellow) }
.bg-orange{ background-color:var(--orange) }
.bg-orange2{ background-color:var(--orange2) }
.bg-green{ background-color:var(--green) }
.bg-skyblue{ background-color:var(--skyblue) }
.bg-purple{ background-color:var(--purple) }
.bg-facebook{ background-color: #1877f2; border-color: #1877f2;}