.content-login{
    width: 90%;
    @media (max-width:$tablet){
        padding-bottom: 10vw;
    }
    .title{
        font-size: 2rem;
        color: var(--black);
        margin-top: 10vw;
        font-family: "Montserrat-ExtraBold";
        color: var(--blue);
        @media (max-width:$tablet){
            font-size: 8vw;
        }
    }
    .text-size-min{
        font-size: .9vw;
        @media (max-width:$tablet){
            font-size: 3.2vw;
        }
    }
    .form-control {
        @media (max-width:$tablet) {
            padding: 6vw 1.2rem;
            font-size: 4vw;
        }
    }
    .btn{
        // padding: .8rem 1.2rem;
        z-index: 2;
    }
    .separator-to-button{
        position: relative;
        width: fit-content;
        &:before,&:after{
            content:'';
            position: absolute;
            width: 2rem;
            height: .0625rem;
        }
        &:before{
            right: 150%;
            background-image: linear-gradient(45deg,transparent,#bdc5d1);
            background-repeat: repeat-x;
        }
        &:after{
            left: 150%;
            background-image: linear-gradient(45deg,#bdc5d1,transparent);
            background-repeat: repeat-x;
        }
    }
}

.page-login{
    @media (max-width:$tablet){
        .header{
            box-shadow: unset;
            .logo-texto{
                background-color: var(--black) !important;
            }
        }
        .footer{
            display: none;
        }
        .content-login{
            .title{
                margin-top: 5vw;
            }
        }
        label{
            font-family: "Montserrat-Regular";
        }
    }
    .content-login{
        @media (min-width:$tablet){
            width: 360px;
            .title{
                margin-top: 0px;
            }
        }
    }
    .section-footer{
        margin-top: 2rem;
    }
}