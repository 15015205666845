.menu-bars {
  position: relative;
  display: inline-block;
  z-index: 9999;
  margin-left: 1rem;
  padding: 0.2rem 0rem;
  .main-bar {
    position: relative;
    width: 6vw;
    height: .8vw;
    background: $off-white;
    border-radius: 3rem;
    @include transition($transition-duration/2);
    &:before,
    &:after {
      position: absolute;
      content: '';
      display: block;
      height: .8vw;
      width: 6vw;
      background: $off-white;
      z-index: -1;
      border-radius: 3rem;
      @include transition($transition-duration/2);
      @include rotate(0);
    }
    &:before {
      top: 1.8vw;
    }
    &:after {
      top: -1.8vw;
    }
  }
}


header{
    position: fixed;
    width: 100%;
    height: auto;
    z-index: 9;
    transform: translateY(0);
    top: 0;
    left: 0;
    transition: 100ms ease padding-top;
    box-shadow: 0px 0px 2rem 0 rgba(41,48,66,.1);
    @media (max-width:$tablet){
        padding-top: 3%;
        position: absolute;
    }

    .menu-login{
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 2;
        pointer-events: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .overlay{
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background: var(--black);
            opacity: 0;
            width: 100%;
            height: 100%;
            transition:500ms ease opacity;
            position: absolute;
        }
        .content-formulario{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            opacity: 1;
            height: auto;
            position: absolute;
            opacity: 0;  
            transform: translateX(500px);
            height: 100%;
            background: #fff;
            transition: 700ms ease transform, 500ms ease opacity; 
            box-shadow: -13px 0 20px rgba(119,131,143,.08);         
            .close-menu-login{
                border: 0px;
                background: transparent;
                position: absolute;
                font-size: 1.5vw;
                top: 2%;
                right: 8%;
                color: var(--blue);
                &:focus{
                    outline: unset;
                }
            }  
            .content-login{
                padding: 8% 10%;
                width: 28vw;
                .title{
                    margin-top: 0vw;
                }
                .form-control{
                    padding: 1.3rem 1.2rem;
                }
                .btn {
                    padding: 0.6rem 1.2rem;
                    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
                    font-weight: bold;
                }
            }
        }
        &.active{
            pointer-events: all;
            .overlay{
                transform: scale(1);
                opacity: .8;
            }
            .content-formulario{
                opacity: 1;
                transition-delay: 300ms;
                transform: translateX(0);
            }
        }
    }

    .top-header{
        position: absolute;
        display: flex;
        transition: 300ms ease all;
        @media (max-width:$tablet) {
            display: none;
        }
        ul{
            padding:.3% 5.7% .3% 0%;
            position: relative;
            &::before{
                content: '';
                width: 0;
                height: 0;
                border-right: .6vw solid transparent;
                border-top: 1vw solid transparent;
                border-left: .6vw solid var(--gray-3);
                border-bottom: 1vw solid var(--gray-3);
                position: absolute;
                left: -1.2vw;
                top: 0;
                transform: rotate(180deg);
            }
            li{
                margin-left: 10px;
                margin-right: 10px;
                font-size: .9vw;
                position: relative;
                display: flex;
                align-items: center;
                font-family: "Work Sans";
                font-weight: 400;
                a{ 
                    color: #293042; 
                    font-family: 'ArialRoundedMTStd-Bold';
                }
                &.line-separator{
                    &::before{
                        content: '';
                        width: 1px;
                        height: 80%;
                        position: absolute;
                        left: -100%;
                        background-color: var(--black);
                    }
                }
                &.icon-social{
                    margin-left: 5px;
                    margin-right: 5px;
                    font-size: 1vw;
                }
            }
        }
        @media (max-width:662px){
            display: none;
        }
    }

    .main-header{
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 3.1%;
        padding-bottom: 1.15%;
        background-color: #fff;
        transition: 300ms ease background-color, 300ms ease padding ;
        justify-content: space-between;
        @media (max-width:$tablet) {
            padding-top: 0;
            padding-bottom: 0;
            background: transparent;
            transition: 1000ms ease background-color;
        }
        .content-logo{
            height: 100%;
            transform: translateY(-1%);
            transition: 300ms ease transform;
            @media (max-width:$tablet){
                transform: translateY(0%);
                position: relative !important;
            }
            .logo{
                background: rgb(255,226,69);
                background: linear-gradient(30deg, rgba(255,226,69,1) 0%, rgba(255,118,50,1) 79%);
                width: 4vw;
                height: 4vw;
                transition:500ms ease all;
                @media (max-width:$tablet) {
                    width: calc(4.5vw + 9vw);
                    height: calc(4.5vw + 9vw);
                    background: rgb(255,226,69);
                    background: linear-gradient(30deg, rgba(255,226,69,1) 0%, rgba(255,118,50,1) 79%);
                }
            }
            .logo-texto{
                background-color: var(--black);
                transition: 500ms ease all;
                width: 16vw;
                height: 4.3vw; 
                opacity: .8;
                @media (max-width:$tablet) {
                    width: calc(20vw + 28vw);
                    height: calc(4.8vw + 10vw);
                    background-color: #fff;
                }
            }
        }
        .names-user-profile{
            display:none;
        }
        .content-menu{
            .menu-movil{
                display: flex;
                align-items: center;
                .bg-menu-movil{
                    position: fixed;
                    width: 100%;
                    height: 100vh;
                    background: var(--black);
                    opacity: 0;
                    pointer-events: none;
                    transition:300ms ease opacity, 300ms ease transform;
                }
                .content-menu-movil{
                    position:fixed;
                    opacity:0;
                    z-index: 2;
                    pointer-events: none;
                    transform: translateX(-100%);
                    transition:500ms ease transform;
                    display:flex;
                    justify-content:space-between;
                    flex-direction: column;
                    overflow-y: auto;
                    max-height: 100vh;
                    .card{
                        border-radius: 1rem;
                        box-shadow: rgba(9, 30, 66, 0.2) 0px 8px 18px;
                    }
                    .menu{
                        display: block;
                        ul{
                            display: block;
                            li{
                                font-size: 1.2rem;
                                margin-left: 0;
                                margin-right: 0;
                                a{
                                    font-family: "ArialRoundedMTStd";
                                    .fa-angle-down{
                                        display:none;
                                    }
                                    div:not(.img-user-profile){
                                        padding-left: 1rem;
                                        padding-top: 1rem;
                                        padding-bottom: 1rem;
                                        border-right: 3px solid transparent;
                                    }
                                    &.active{
                                        color:var(--black);
                                        font-family: "ArialRoundedMTStd-ExtraBold";
                                        div{
                                            border-right: 3px solid var(--orange2);
                                            // background: var(--gray);
                                        }
                                    }
                                }
                                &.icon-person{
                                    // background: linear-gradient(30deg, #ffe245 0%, #ff7632 79%);
                                    &:before{
                                        content:'';
                                        position:absolute;
                                        top: 0;
                                        left: 0;
                                        width:100%;
                                        height: 40vw;
                                        // background: var(--black);
                                        // box-shadow: rgba(9, 30, 66, 0.15) 0px 8px 18px;
                                    }
                                    .content-user-profile-menu{
                                        padding-top: 5vw;
                                        padding-bottom: 5vw;
                                    }
                                    .img-user-profile{
                                        width:13vw;
                                        height: 13vw;
                                        // margin-top: 20vw;
                                        // margin-bottom: 10vw;
                                        margin-left: 1rem;
                                    }
                                    .names-user-profile{
                                        display:flex;
                                        .names{
                                            font-size: 4vw;
                                            color: var(--black);
                                            font-family: "ArialRoundedMTStd-ExtraBold";
                                        }
                                        .email{
                                            font-size: 3vw;
                                            color: var(--black);
                                            font-family: "ArialRoundedMTStd";
                                        }
                                    }
                                }
                                .submenu{
                                    display: none;
                                }
                            }
                        }
                    }
                    .bottom-menu{
                        ul{
                            display:flex;
                            justify-content:space-between;
                            li{
                                background-color: var(--gray-2);
                                width:100%;
                                padding: 4vw 1.5vw;
                                a{
                                    color: var(--black);
                                    div{
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
                &.open {
                    .menu-bars{
                        .main-bar {
                        background: transparent;
                        @include animation(mainTrans 0.5s);
                        &:before {
                            top: 0;
                            @include rotate(45deg);
                            @include animation(beforeTrans 0.5s);
                        }
                        &:after {
                            top: 0;
                            @include rotate(-45deg);
                            @include animation(afterTrans 0.5s);
                        }
                        }
                    }
                    .bg-menu-movil{
                        opacity: .8;
                        pointer-events: all;
                    }
                    .content-menu-movil{
                        opacity: 1;
                        transform: translateX(0%);
                        pointer-events: all;
                    }
                }
                @media (min-width:$tablet) {
                    display: none;
                }
            }
            .menu{
                ul{ 
                    display: flex;
                    align-items: center;
                    li{
                        margin-left: .9vw;
                        margin-right: .9vw;
                        position: relative;
                        font-size: 1.1vw;
                        @media (max-width:$tablet){
                            display:none;
                        }
                        a{
                            transition: 500ms ease all;
                            // color: var(--black);
                            color:#293042;
                            font-family: "Montserrat-Bold";
                            // &::before{
                            //     content:'';
                            //     bottom: -.2vw;
                            //     position: absolute;
                            //     width: 0%;
                            //     height: .20vw;
                            //     background: linear-gradient(0deg, #ffd71d 0%, #ffa31d 100%);
                            //     transition: 500ms ease all;
                            // } 
                            .icon-btc{
                                width: 2vw;
                                height: 2vw;
                                // background: rgb(255,226,69);
                                // background: linear-gradient(30deg, rgba(255,226,69,1) 0%, rgba(255,118,50,1) 79%);
                            }
                            &.active{
                                opacity: 1;
                                color: var(--orange);
                                &::before{
                                    width: 50%;
                                }
                            }
                            .fa-angle-down{
                                margin-left: 5px;
                            }
                        }
                        &:hover{
                            a{
                                opacity: 1;
                            }
                        }
                        .img-user-profile{
                            width: 2.5vw;
                            height: 2.5vw;
                            border: 2px solid #D2D5D9;
                            @media (max-width:$tablet) {
                                width: 10vw;
                                height: 10vw;
                            }
                        }
                        &.icon-person{
                            opacity: 1;
                            @media (max-width:$tablet) {
                                display: block;
                            }
                            a{
                                opacity: 1;
                            }
                            .mask-contain{
                                width: 2.5vw;
                                height: 2.5vw;
                                transition: 500ms ease all;
                                background: var(--orange);
                                background: linear-gradient(30deg, rgba(255,226,69,1) 0%, rgba(255,118,50,1) 79%);
                                @media (max-width:$tablet) {
                                    width: 10vw;
                                    height: 10vw;
                                }
                            }
                        }
                        .img-user-profile{
                            position: relative;
                            // &::before{
                            //     content:'';
                            //     position: absolute;
                            //     width: 10px;
                            //     height: 10px;
                            //     background: var(--orange);
                            //     top: 0;
                            //     right: 2px;
                            //     border-radius: 50%;
                            // }
                        }
                        .submenu{
                            top: 140%;
                            right: 0%;
                            position: absolute;
                            flex-direction: column;
                            background-color: #fff;
                            border-radius: 8px;
                            filter: drop-shadow(0px 0px 2px rgba(1, 46, 64, 0.2));
                            transition: 300ms ease all;  
                            width: max-content;
                            opacity: 0;
                            transform: scale(.7);
                            pointer-events: none;
                            &.open,&:hover{
                                opacity: 1;
                                pointer-events: all;
                                transform: scale(1);
                            }
                            a{
                                font-family: "Work Sans";
                                color: var(--black) !important;
                                font-size: 15px;
                                position: relative;
                                display: block;
                                width: 100%;
                                padding: .5rem 1.2rem;
                                clear: both;
                                background: transparent;
                                transition: unset;
                                opacity: 1;
                                i{
                                    margin-right: 10px;
                                }
                                &:hover{
                                    background: var(--gray);
                                }
                                &:before{
                                    content:unset;
                                }
                                &:first-child{ 
                                    border-top-left-radius: 8px;
                                    border-top-right-radius: 8px;
                                    &::after{
                                        content: '';
                                        position: absolute;
                                        height: 0;
                                        width: 0;
                                        right: 1em;
                                        top: -6px;
                                        border: 6px solid transparent;
                                        border-top: 0;
                                        border-bottom-color: #fff !important;
                                        opacity: 1;
                                    } 
                                }
                                &:last-child{
                                    border-bottom-left-radius: 8px;
                                    border-bottom-right-radius: 8px;
                                }
                            }
                        }
                    } 
                }
            }
        }
    }

    &.black-noTop{
        @media (max-width:$tablet){
            position: fixed;
            padding-top: 0%;
            .main-header{
                padding-top: 3vw;
                padding-bottom: 3vw;
                background-color: var(--black);
                // background-color: #fff;
                height: 19vw;
                .content-logo{
                    @media (max-width:$tablet){
                        transform: translateY(-1%);
                        // .logo-texto{
                        //     background-color:var(--black);
                        // }
                    }
                }
            }
        }
    }
}

.page-home,.page-nosotros{
    header{
        &.black-noTop{
            // @media (max-width:$tablet){
            //     .main-header{
            //         background-color: transparent;
            //     }
            // }
            @media (min-width:$tablet){
                box-shadow: unset;
                .top-header{
                    opacity: 0;
                    pointer-events: none;
                }
                .main-header{
                    background-color: transparent;
                    .content-logo{
                        transform: translateY(15%);
                        .logo{
                            background: rgb(255,226,69);
                            background: linear-gradient(30deg, rgba(255,226,69,1) 0%, rgba(255,118,50,1) 79%);
                        }
                        .logo-texto{ 
                            background-color: #fff;
                        }
                    }
                    .content-menu{
                        .menu{
                            ul li{
                                a{
                                    color: #fff;
                                    &.active{
                                        color: var(--orange);
                                    }
                                }
                                &.icon-person .mask-contain{
                                    background: var(--orange);
                                    background: linear-gradient(30deg, rgba(255,226,69,1) 0%, rgba(255,118,50,1) 79%);
                                }
                            } 
                        }
                    }
                }
            }
        }
    }
}