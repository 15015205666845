.content-loading{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: 500ms ease all;
    &.active{
        transition: 0ms ease all;
        pointer-events: all;
        opacity: 1;
    }
    @keyframes spin {
        from {
          transform: rotate(0);
        }
        to{
          transform: rotate(359deg);
        }
    }      
    .spinner-box {
        width: 300px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }
      
    .circle-border {
        width: 70px;
        height: 70px;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: rgb(63,249,220);
        background: linear-gradient(0deg, rgba(255, 215, 29, 0.2) 33%, rgba(255, 163, 29, 1) 100%);
        animation: spin .8s linear 0s infinite;
    }
      
    .circle-core {
        width: 100%;
        height: 100%;
        background-color:  var(--black);
        border-radius: 50%;
    }
}