.page-pago{
    background: #f4f5f7;
    @media (max-width:$tablet){
        padding-top: 0px !important;
    }
    .form-control{
        padding: .375rem .75rem;
    }
    @media (max-width:$tablet){
        .row{
            .row{
                margin-left: 0px !important;
                margin-right: 0px !important;
            }
        }
        .img-selected-voucher{
            width:50%;
            margin-top: 1rem;
        }
        .flex-column-sm{
            flex-direction: column;
        }
        .sm-w-100{
            width:100% !important;
        }
    }
    .pt-126{
        padding-top: 126%;
        border-radius: 1rem;
    }
}