.page-nosotros{
    background: #f4f5f7;
    @media (max-width:$tablet){
        padding-top: 0px !important;
    }
    .info-aboutus{
        margin-top: 4%;
        margin-bottom: 2%;
        .title{
            font-size: 3vw;
        }
        .img{
            width: 40%;
            border-radius: 1rem;
            border: 0px;
            box-shadow: rgba(9, 30, 66, 0.2) 0px 8px 18px;
        }
    }
    .icon-property{
        background: #ff7632;
        background: linear-gradient(90deg, #ff7632 0%, #ffe245 100%);
        width: 100px;
        height: 100px;
        margin: auto;
    }
    @media (max-width:$tablet){
        .info-aboutus{
            // margin-top: 2.5rem;
            margin-top: 5%;
            margin-bottom: 13%;
            .title{
                font-size: 10vw;
            }
            .img{
                width: 100%;
                margin: auto;
                // transform: translateX(3%);
                margin-top: 7%;
                margin-bottom: 5%;
            }
            .d-flex{
                flex-direction: column;
            }
        }
    }
}