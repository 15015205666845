.page-activacion{
    background: rgb(244, 245, 247);
    color: var(--black);
    font-family: "ArialRoundedMTStd";
    @media (max-width:$tablet){
        margin-top: 1.5rem;
    }
    .card{
        
        border-radius: 5px;
        border:0px;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px;
        .card-body{
            padding:3rem 1.5rem;
            h1{
                font-size: 2rem;
                font-family: "ArialRoundedMTStd-ExtraBold";
            }
        }
        
    }
}