.page-operaciones-entrantes{
    @media (max-width:$tablet){
        padding-top: 0px !important;
    }
}
.modal-detalle-extend{
    .modal-body{
        font-size: .8rem;
        @media (max-width:$tablet){
            font-size: .95rem;
        }
    }
}