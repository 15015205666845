$tablet:769px;
.page {
    @media (max-width:$tablet) {
        padding-top: 19vw !important;
        .w-sm-85{
            width:85% !important;
        }
    }

    .card {
        border-radius: 1rem;
        // border: 0px;
        box-shadow: rgba(9, 30, 66, 0.1) 0px 8px 18px;
        // box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px;
        &.no-shadow{
            box-shadow: unset;
            border:0px;
        }
        .card-header{
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }

        .card-header {
            background: #fff;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            border-bottom:0px;
            font-size: 1.3vw;
            @media (max-width:$tablet){
                font-size: 4.5vw;
            }
        }

        .card-footer{
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }
        
        .card-footer{
            padding-bottom: 1.5rem;
        }

        .embed-responsive{
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }
    }
}

#fp-nav {
    z-index: 1;
}

.p-fixed {
    position: fixed;
}

.dropdown-menu{
    border-radius: 8px;
    filter: drop-shadow(0px 0px 2px rgba(1, 46, 64, 0.2));
    border-color: transparent;
}

.bg-shadow-button {
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px !important;
}

.shadow-lg-2 {
    box-shadow: rgba(9, 30, 66, 0.28) 0px 4px 8px -2px, rgba(9, 30, 66, 0.3) 0px 0px 1px !important;
}

.list-group-item {
    color: var(--black);
    font-family: "ArialRoundedMTStd";
    border: 0px;
    &.active{
        background: linear-gradient(30deg, #ffe245 0%, #ff7632 79%);
        border-color: var(--orange);
        color: var(--black);
    }
    &:last-child {
        border-bottom-right-radius: 1rem !important;
        border-bottom-left-radius: 1rem !important;
    }
}

@media (max-width:$tablet) {
    .section {
        position: relative;
        padding-top: 16vw;
        padding-bottom: 16vw;
    }

    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        padding-right: 0px;
        padding-left: 0px;
    }

    .tramado{
        display: none;
    }

    .next-page {
        display: none !important;
    }

    .gm-svpc {
        display: none !important;
    }

    .gmnoprint {
        top: unset !important;
        bottom: 4.5rem !important;
    }

    .container {
        width: 90% !important;
        max-width: 90% !important;
    }

    .col-md-3,
    .col-md-4,
    .col-md-8 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.w-90 {
    width: 90%;
}

.w-80 {
    width: 80%;
}

.w-70 {
    width: 70%;
}

.w-60 {
    width: 60%;
}

.w-40 {
    width: 40%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.w-10 {
    width: 10%;
}

.input-group-text {
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
}

#fp-nav ul li .fp-tooltip {
    top: 15px !important;
    font-family: "ArialRoundedMTStd-ExtraBold" !important;
}

.next-page {
    z-index: 2;
    font-size: 3vw;
    justify-content: center;
    width: 100%;
    display: flex;
    z-index: 5;
    transform: translateY(-10px);

    .icon-down {
        -webkit-animation-name: slideInDown2;
        animation-name: slideInDown2;
        animation-duration: 600ms;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        width: 2vw;
        height: 2vw;
        cursor: pointer;
    }
}

ul {
    list-style: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

a:link,
a {
    text-decoration: none;
}

.content-center {
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 0;
}

.fp-right {
    margin-top: -19.5px !important;

    li:last-child {
        display: none !important;
    }
}

.p-absolute {
    position: absolute;
}

.top-0 {
    top: 0;
}

.left-0 {
    left: 0;
}

.bottom-0 {
    bottom: 0;
}

.right-0 {
    right: 0;
}

.pointer-none {
    pointer-events: none;
}
.control-label{
    font-weight: bold;
}
.form-control {
    border-radius: 3rem;
    // border-radius: 5rem; 
    // border: 0px solid #ced4da;
    transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
    // padding: .375rem 1.2rem;
    // padding: 1.3rem 1.2rem;
    // border: 1.5px solid #ced4da;
    background-color: rgb(235, 236, 240);

    @media (max-width:$tablet) {
        // height: 10vw !important;
        // padding: 6vw 1.2rem;
        // font-size: 4vw;
        height: 12vw;
    }

    &.is-invalid {
        background-color: #fff;
    }
}

h1 {
    font-size: 4vw;
    position: relative;
    margin-bottom: .6rem;

    @media (max-width:$tablet) {
        font-size: 10vw;
        margin-bottom: 7vw;
        z-index: 3;
    }

    &.c-orange {
        &::before {
            background-color: var(--orange);
        }
    }

    &.c-blue {
        &::before {
            background-color: var(--blue);
        }
    }

    &.c-green {
        &::before {
            background-color: var(--green);
        }
    }
}

h4 {
    font-size: 1.4vw;
    margin-bottom: 1.5rem;

    @media (max-width:$tablet) {
        font-size: 5vw;
        margin-bottom: 5vw;
    }
}

h2 {
    @media (max-width:$tablet) {
        font-size: 7vw;
    }
}

.form-control {

    &:focus,
    &:active {
        background-color: #fff !important;

        &::placeholder {
            color: transparent;
        }
    }

    &:hover {
        background-color: rgb(223, 225, 230);
    }
}

.p-relative {
    position: relative;
}

.content-confetti {
    position: absolute;
    top: 0;

    &.confetti-left {
        left: 8%;
    }

    &.confetti-right {
        right: 8%;
        -moz-transform: scaleX(-1) !important;
        -o-transform: scaleX(-1) !important;
        -webkit-transform: scaleX(-1) !important;
        transform: scaleX(-1) !important;
    }
}

.confetti {
    width: 50px;
    height: 50px;
}

.input-group {
    position: relative;

    .input-group-text {
        top: 0;
        z-index: 2;
        height: 100%;
        color: rgb(94, 108, 132);

        @media (max-width:$tablet) {
            font-size: 4vw;
        }
    }

    .input-group-prepend {
        .input-group-text {
            background-color: transparent;
            position: absolute;
            left: 0;
            border: 0px solid transparent;
            border-right: 0px;
        }

        &+.form-control {
            padding-left: 2.5rem !important;
        }
    }

    .input-group-append {
        .input-group-text {
            position: relative;
            right: 0;
            cursor: pointer;
            border-top-right-radius: 3rem;
            border-bottom-right-radius: 3rem;
        }
    }

    .form-control {
        font-family: "ArialRoundedMTStd-Light";

        &:focus {
            z-index: 1;
        }

        &:not(:first-child) {
            border-top-left-radius: 3rem;
            border-bottom-left-radius: 3rem;
        }
    }
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border-radius: 5rem;
    // padding: .4rem 1.8rem;
    padding: .375rem 1.5rem;
    font-family: "Montserrat-Bold";
    transition: 300ms ease all;

    @media (max-width:$tablet) {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 4vw;
        font-weight: bold;
        padding: 3vw 2.5rem;
    }

    &:hover,
    &:active {
        transform: scale(1.05);
    }

    i {
        font-size: 1rem;

        @media (max-width:$tablet) {
            font-size: 5vw;
        }
    }

    // color: var(--black);

    &.btn-warning {
        background: linear-gradient(30deg, #ffe245 0%, #ff7632 79%);
        border: 0px solid transparent;
    }

    &.btn-light {
        background-color: var(--gray);
        border-color: var(--gray);
    }

    &.btn-square {
        border-radius: 3px;
        border: 1px solid rgba(0,0,0,.125);
    }
}

/*Radio input*/
$color1: #ebecf0;
$color2: var(--orange);
.radio {
    margin: 0.5rem;
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        +.form-check-label {
            &:before {
                content: '';
                background: $color1;
                border-radius: 100%;
                border: 2px solid #ced4da;
                display: inline-block;
                width: 1.4em;
                height: 1.4em;
                position: relative;
                margin-right: .5em;
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }
        &:checked {
            +.form-check-label {
                &:before {
                    background-color: $color2;
                    box-shadow: inset 0 0 0 4px #fff;
                    outline: none;
                    border-color: $color2;
                }
            }
        }
        &:disabled {
            +.form-check-label {
                &:before {
                    box-shadow: inset 0 0 0 4px $color1;
                    border-color: darken($color1, 25%);
                    background: darken($color1, 25%);
                }
            }
        }
        +.form-check-label {
            &:empty {
                &:before {
                    margin-right: 0;
                }
            }
        }
    }
}

/*BG / MASK Size*/
.mask-cover {
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center center;
}

.mask-contain {
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center center;
}

.bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-contain {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

/*Banner Page (not Home)*/
.banner-page {
    // height: 30vw;
    height: 15vw;
    display: flex;
    align-items: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--black);
        opacity: .9;
    }

    // &::after {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     background: #f4f5f7;
    //     -webkit-mask-image: url("./../../img/bottomPart.svg");
    //     -webkit-mask-size: cover;
    //     -webkit-mask-repeat: no-repeat;
    //     -webkit-mask-position: center center;
    //     padding-top: 8.7%;
    // }

    .form-group{
        width:30rem;
        @media (max-width:$tablet){
            width:85vw
        }
    }

    .text-encabezado {
        font-size: 2.6vw;
        line-height: 1em;
        display: flex;
        justify-content:center;
        align-items: center;
        flex-direction: column;
        left: 0;
        width: 100%;
        text-align: center;
        @media (min-width:$tablet){
            height: 100%;
            top: 0;
        }
    }

    @media (max-width:$tablet) {
        height: 60vw;
        display: flex;
        align-items: center;

        .text-encabezado {
            transform: translateY(35%);
            font-size: 5vw;
        }
    }
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: var(--black);
    border-color: var(--black);
}

.page-link {
    color: var(--black);
    &:focus {
        z-index: 3;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(1,6,38,.25);
    }
}

a{
    color: var(--purple);
}
.text-purple{
    color: var(--purple);
}
.text-small{
    font-size: 80%;
    font-weight: 400;
}

button{
    .spinner-border{
        display: none;
    }
    &[disabled]{
        .spinner-border{
            display: inline-block !important;
        }
        .text-button{
            display: none !important;
        }
    }
}

.modal-header{
    button:focus {
        outline: 0px !important;
    }
}

.border-top-card{
    border-top: 3px solid var(--orange) !important;
}
.border-left-card{
    border-left: 3px solid var(--orange) !important;
}
.border-right-card{
    border-right: 3px solid var(--orange) !important;
}
.border-bottom-card{
    border-bottom: 3px solid var(--orange) !important;
}

.fp-enabled body, html.fp-enabled{
    overflow-x: hidden !important;
    overflow-y: visible !important;
}
.swal2-icon.swal2-success [class^=swal2-success-line]{
    background-color: var(--purple) !important;
}