.alert-message{
    z-index: 9;
    bottom: 5%;
    left: 3%;
    transform: translateX(-150%);
    opacity: 0;
    transition: transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s;
    &.show{
        opacity: 1;
        transform: translateX(0%);
    }
    .card-body{
        width: 340px;
        .remove{
            position: absolute;
            top: 3%;
            right: 3%;
        }
    }
    @media (max-width:$tablet){
        pointer-events: none;
        transform: translateX(0%);
        width: 100%;
        left: 0%;
        display: flex;
        justify-content: center;
        .text{
            text-align: center;
        }
        .card{
            box-shadow: unset !important;
            border:0px solid transparent;
            background: rgba(0,0,0,.8);
            border-radius: 3rem;
            width: max-content;
            .card-body{
                color: #fff;
                text-align: center;
                width: max-content;
                padding:.7rem 1.50rem;
                .pl-2{
                    display: none !important;
                }
                i{
                    display: none;
                }
            }
        }
    }
}