.logo-btc{
    display: none;
    font-size: 7vw;
    padding-top: 3vw;
    padding-bottom: 2vw;
}
.contenedor-oculto, .content-progress, .content-bitcoin-logo{
    display: none;
}
.content-progress{
    padding-bottom: 1rem;
    .progress{
        height: 4px;
    }
}
.content-bitcoin-logo{
    .img-logo{
        // background: var(--black);
        padding-top: 10%;
        margin-bottom: 1rem !important;
        @media (max-width:$tablet){
            padding-top: 15%;
        }
    }
}

.content-calculadora-loading{
    z-index: 3;
    background-color: rgba(255,255,255,.7);
    opacity: 0;
    transition: 500ms ease opacity;
    pointer-events: none;
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;
    &.active{
        opacity: 1;
        pointer-events: all;
    }
}

.page-calculator{
    background: rgb(244, 245, 247);
    .contenedor-oculto, .content-progress{
        display: block;
    }
    .content-calculadora-loading{
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
    .content-bitcoin-logo{
        display: flex;
    }
    .logo-btc{
        display: flex;
        font-size: 2rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
    .container{
        padding-top: 2%;
        padding-bottom: 2%;
    }
    .btn{
        margin-top: 1.5rem;
    }
    @media (max-width: $tablet){
        .container{
            padding-top: 5%;
            padding-bottom: 10%;
        }
        .w-50, .w-40{
            width: 90% !important;
        }
        label{
            font-size: 4vw;
            // display: none;
        }
        .calculadora-resultado-visible{
            font-size: 3.5vw !important;
        }
        // .row{
        //     padding:1rem 0rem !important;
        // }
        .cols-p-mobile-none {
            padding-right: 0px;
            padding-left: 0px;
        }
    }
    .tab-header{
        width: 100%;
        margin: auto;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        .tab-pane{
            padding: 0.675rem 1.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Montserrat-ExtraBold";
            width: 50%;
            background: #e9ecef;
            cursor: pointer;
            position: relative;
            transition: 300ms ease all;
            border-top: 3px solid transparent;
            @media (max-width:$tablet){
                font-size: 4vw;
                padding: 1rem 1.2rem;
            }
            &:first-child{
                border-top-left-radius: 1rem;
                &:not(.tab-pane-active){
                    border-bottom-right-radius: 1rem;
                }
            }
            &:last-child{
                border-top-right-radius: 1rem;
                &:not(.tab-pane-active){
                    border-bottom-left-radius: 1rem;
                }
            }
            &.tab-pane-active{
                background: #fff;
                border-top: 3px solid var(--orange);
                color: var(--orange);
                &::before{
                    width: 100%;
                }
            }
        }
    }
    .content-calculadora{
        padding: 3rem 2rem;
        @media (max-width:$tablet){
            padding:2rem 1.25rem;
        }
        .calculadora{
            flex-direction: column;
            .content-cantidad{
                flex-direction: column;
            }
            .col-md-3{
                flex: 0 0 100%;
                max-width: 100%;
            }
            p{
                margin-bottom: 1rem;
            }
            .title-calculator{
                @media (max-width:$tablet){
                    font-size: 4vw;
                }
            }
            .border-calculadora{
                padding:1.25rem !important;
                p{
                    display: none;
                }
            }
            @media (max-width:$tablet){
                .border-calculadora{
                    // padding-bottom: 20vw !important;
                }
            }
        }
    }
    
    .form-control {
        padding: 1.3rem 1.2rem;
        @media (max-width:$tablet) {
            padding: 6vw 1.2rem;
            font-size: 4vw;
        }
    }
    .btn{
        padding: 0.6rem 1.2rem;
        // padding: .8rem 1.2rem;
        @media (max-width:$tablet){
            padding: 3vw 2.5rem;
        }
    }
}